export default {
    breakpoints: ['40em', '56em', '64em'],
    space: [
        0,
        4,
        8,
        16,
        32,
        64,
        128,
        256,
        512
    ],
    fonts: {
        body: 'Montserrat, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        heading: 'Montserrat, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        monospace: 'Menlo, monospace'
    },
    fontSizes: [
        12,
        14,
        16,
        22,
        24,
        36,
        48,
        64,
        96
    ],
    fontWeights: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
    },
    lineHeights: {
        body: 1.5,
        heading: 1
    },
    colors: {
        text: '#213765',
        background: '#EAE7DC',
        primary: '#EE9988',
        secondary: '#E85A4F',
        muted: '#FFFFFF',
        accent: '#213765',
        modes: {
            dark: {
                text: '#FFFFFF',
                background: '#213765',
                primary: '#FFFFFF',
                secondary: '#E85A4F',
                muted: '#FFFFFF',
                accent: '#EE9988',
            }
        }
    },
    buttons: {
        primary: {
            color: 'muted',
            bg: 'secondary',
            py: [1, 1, 1],
            fontFamily: 'body',
            fontSize: [1, 2, 2],
            cursor: "pointer",
            minWidth: "80px"
        },
        form: {
            color: 'text',
            bg: 'secondary',
            fontFamily: 'body',
            cursor: 'pointer',
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            py: 0,
            px: 0,
            height: "100%",
            "&:hover": {
                bg: "accent"
            }
        },
    },
    layout: {
        flexContainer: {
            maxWidth: "100%",
            px: [3, 4, 5],
            mt: [4, 4, 4],
            mb: [5, 5, 6],
            width: "100vw"
        },
        container: {
            maxWidth: 1024,
            px: [3, 4, 5],
            mb: [5, 5, 6]
        },
        longFormContainer: {
            maxWidth: 800,
            px: [3, 4, 5],
            mb: [5, 5, 6]
        },
    },
    styles: {
        root: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'regular'
        },
        h1: {
            color: 'text',
            fontFamily: 'heading',
            lineHeight: 'heading',
            letterSpacing: '0.08em',
            fontWeight: 'bold',
            fontSize: ["8vw", 6, 7],
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        h2: {
            paddingTop: [15, 30, 30],
            color: 'text',
            fontFamily: 'heading',
            lineHeight: 'body',
            fontWeight: 'regular',
            fontSize: [4, 5, 6]
        },
        h3: {
            color: 'text',
            fontFamily: 'heading',
            lineHeight: 'body',
            fontWeight: 'regular',
            fontSize: [3, 5, 5],
            letterSpacing: '0.08em',
            maxWidth: ["100%", "85%", "80%"],
            paddingBottom: [20, 20, 20]
        },
        h4: {
            color: 'text',
            fontFamily: 'heading',
            lineHeight: 'body',
            fontWeight: 'regular',
            fontSize: [2, 3, 3],
            whiteSpace: "nowrap"
        },
        h5: {
            color: 'secondary',
            fontFamily: 'heading',
            lineHeight: 'heading',
            fontWeight: 'bold',
            fontSize: 1
        },
        h6: {
            color: 'text',
            fontFamily: 'heading',
            lineHeight: 'heading',
            fontWeight: 'bold',
            fontSize: 0
        },
        p: {
            color: 'text',
            fontFamily: 'body',
            fontWeight: 'regular',
            lineHeight: 'body'
        },
        a: {
            color: 'primary',
            textDecoration: 'none',
            "&:hover": {
                color: "accent",
                cursor: "pointer"
            }

        },
        pre: {
            fontFamily: 'monospace',
            overflowX: 'auto',
            code: {
                color: 'inherit'
            }
        },
        code: {
            fontFamily: 'monospace',
            fontSize: 'inherit'
        },
        table: {
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0
        },
        th: {
            textAlign: 'left',
            borderBottomStyle: 'solid'
        },
        td: {
            textAlign: 'left',
            borderBottomStyle: 'solid'
        },
        img: {
            display: 'inline',
            margin: "0 0.125em",
            padding: 0,
            verticalAlign: 'baseline'
        }
    }
}